/* @import url('https://bimape-bucket-2.s3.us-east-2.amazonaws.com/PlusJakartaSans-Regular.ttf');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap'); */

/* Jakarta Font */
@font-face {
	font-family: 'Jakarta';
	src: url('./assets/fonts/PlusJakartaSans-Medium.ttf');
}

* {
	font-family: 'Jakarta';
	margin: 0;
	padding: 0;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}
p {
	padding: 0;
}

body {
	background-color: #f5f5f5;
	min-height: 100%;
	height: 100%;
}
html,
#root {
	height: 100%;
}
a {
	text-decoration: none;
}

.tippy-popper {
	pointer-events: auto !important;
}

/* to prevent blurry content in tooltips */
.tippy-popper,
.tippy-tooltip,
.tippy-tooltip-content {
	-webkit-font-smoothing: antialiased !important;
}

.tippy-tooltip {
	padding: 0rem 0.1rem !important;
}

.flex-container {
	display: flex;
	align-items: center;
}
.full-flex-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/* image preview modal */
#image_preview {
	max-width: 70vw !important;
}

a:link {
	text-decoration: none;
}

/* help modal */
#help-modal .modal-title {
	width: 100%;
}
#help-modal {
	max-width: 800px !important;
}
